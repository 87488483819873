<template>
  <my-progress-graph :ranged-dataset="rangedDataset" :filters="filters" :header-size="300">
    <template v-slot:filters>
      <my-progress-balance-filters v-if="oldestCfMonth" :filters="filters" :oldest-cf-month="oldestCfMonth"
                                   @filters-changed="filtersChanged" />
    </template>
    <template v-slot:header>
      <my-progress-balance-header v-if="oldestCfMonth" :ranged-dataset="rangedDataset" :oldest-cf-month="oldestCfMonth"
                                  :filters="filters" @filters-changed="filtersChanged" />
    </template>
    <template v-slot:graph="graphSlotProps">
      <div>
        <div class="graph-labels">
          <div class="margin-top-m margin-right-sm">
            <div class="ri-medium-headline">
              {{ avgCashflows | signedFormatAmountWithFraction(0) | shekel }}
            </div>
            <div class="ri-label">ממוצע חודשי</div>
          </div>
          <share-button filename="my-balance-progress" share-name="MyBalanceProgress"
                        :share-component="shareComponent"
                        :share-component-props="getShareComponentProps(graphSlotProps)"
                        :segment-extra-props="{ filters }" v-slot="{ onClick }">
            <icon class="share-icon" icon-name="share" fill weight="bold" tabindex="0" @click="onClick"
                  @keyup.enter="onClick"/>
          </share-button>
        </div>
        <bar-graph-v2 v-bind="getBarGraphProps(graphSlotProps)"/>
      </div>
    </template>

    <div class="padding-m margin-top-m" v-if="this.cashflowsSteps.length > 0">
      <separator/>
      <div class="ri-bold-title margin-top-m">הצעדים הבאים שלך</div>
      <component v-for="step in cashflowsSteps" :key="step.actionKey" :is="step.cardComponent" class="margin-top-m"/>
    </div>

  </my-progress-graph>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import BarGraphV2 from '@/base-components/bar-graph-v2/BarGraphV2';
import { mapActions, mapGetters, mapState } from 'vuex';
import ShareButton from '@/base-components/share/ShareButton';
import Segment from '@/Segment';
import * as HamsterApi from '@/api/HamsterApi';
import dateUtils from '@/utils/dates';
import moment from 'moment';
import {
  ActionKeys, actionKeyToComponent, progressStatusToActionKeys,
} from '../next-steps/my-progress-next-steps.consts';
import MyProgressShareAsset from '../MyProgressShareAsset';
import MyProgressBalanceHeader from './MyProgressBalanceHeader.vue';
import MyProgressBalanceFilters from './MyProgressBalanceFilters.vue';
import MyProgressGraph from '../MyProgressGraph';
import { BALANCE_DATA_SECTION_FILTERS_STRINGS, DATE_FILTERS_STRINGS } from '../my-progress-filters.consts';

export default {
  name: 'MyProgressBalanceSummary',
  components: {
    BarGraphV2,
    MyProgressBalanceHeader,
    MyProgressBalanceFilters,
    MyProgressGraph,
    ShareButton,
    Icon: BaseUI.Icon,
    Separator: BaseUI.Separator,
  },
  data() {
    return {
      dataset: null,
      filters: {
        dataSections: {
          ...BALANCE_DATA_SECTION_FILTERS_STRINGS.cashflows,
          excluded: false,
          cashflows: true,
        },
        dates: {
          filterKey: 'last-12-months',
          ...DATE_FILTERS_STRINGS['last-12-months'],
          start: null,
          end: null,
        },
      },
      barStyles: {
        width: '12px',
        borderRadius: '10px',
      },
      shareComponent: MyProgressShareAsset,
    };
  },
  async created() {
    // TODO Rotem asked to add a property of "source": 'overview'. Ask Rotem if it's needed
    Segment.trackUserGot('MyProgress_Cashflow_Graph_Shown');
    this.dataset = await HamsterApi.getRiseupPool();
    const newestCashflowMonth = this.dataset[this.dataset.length - 1].cashflowMonth;
    this.filters.dates.end = newestCashflowMonth;
    this.filters.dates.start = dateUtils.formatCashflowDate(moment(newestCashflowMonth).subtract(11, 'month'));
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
      textColor: BaseUI.Colors.riseupDarkGreen,
      logoColor: BaseUI.Colors.riseupDarkGreen,
    });
    if (_.isEmpty(this.cashflow)) {
      await this.initBudgets({ budgetDate: 'current' });
    }
    this.initCustomerProgress().catch();
  },
  computed: {
    ...mapState('customerProgress', ['customerProgress']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    rangedDataset() {
      const monthsInRange = moment(this.filters.dates.end).diff(this.filters.dates.start, 'months') + 1;
      const cashflowMonths = _.times(monthsInRange, i => dateUtils.formatCashflowDate(moment(this.filters.dates.start).add(i, 'months')));
      return cashflowMonths.map(month => this.dataset.find(({ cashflowMonth }) => cashflowMonth === month) || { cashflowMonth: month });
    },
    possibleSteps() {
      return [this.predictedBalanceAtEndOfMonth >= 0 && ActionKeys.SET_MONTHLY_GOAL, ActionKeys.TRIAL_ACTIONS];
    },
    progressStatus() {
      return this.customerProgress?.progressState?.progressStatus;
    },
    cashflowsSteps() {
      if (!this.progressStatus) {
        return [];
      }
      const customerSteps = progressStatusToActionKeys[this.progressStatus];
      return _.chain(this.possibleSteps)
        .filter(step => customerSteps.includes(step))
        .map(actionKey => {
          return { actionKey, ...actionKeyToComponent[actionKey] };
        }).value();
    },
    oldestCfMonth() {
      return _.minBy(this.dataset, ({ cashflowMonth }) => cashflowMonth)?.cashflowMonth;
    },
    avgCashflows() {
      return _.chain(this.rangedDataset)
        .filter(item => 'cashflowBalance' in item)
        .map(item => {
          const allExcluded = item.excludedSavings + item.incomeExclusionsThatAreNotSavings + item.expenseExclusionsThatAreNotSavings;
          return 0 + (this.filters.dataSections.cashflows ? item.cashflowBalance : 0)
                + (this.filters.dataSections.excluded ? allExcluded : 0);
        })
        .mean()
        .value();
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('customerProgress', ['initCustomerProgress']),
    ...mapActions('cashflowView', ['initBudgets']),
    valueFormatter({ cashflowMonth, ...otherValues }) {
      if (_.isEmpty(otherValues)) {
        return null;
      }
      const {
        cashflowBalance,
        expenseExclusionsThatAreNotSavings,
        incomeExclusionsThatAreNotSavings,
        excludedSavings,
      } = otherValues;
      const allExcluded = excludedSavings + incomeExclusionsThatAreNotSavings + expenseExclusionsThatAreNotSavings;
      const value = 0
        + (this.filters.dataSections.cashflows ? cashflowBalance : 0)
        + (this.filters.dataSections.excluded ? allExcluded : 0);
      return { value, style: { backgroundColor: value >= 0 ? BaseUI.Colors.riseupGreen : BaseUI.Colors.riseupDangerRed } };
    },
    filtersChanged(filters) {
      this.filters = filters;
    },
    getBarGraphProps({ rangedDataset, labelFormatter, xTickFormatter, yTickFormatter }) {
      return {
        rawValues: rangedDataset,
        valueFormatter: this.valueFormatter,
        labelFormatter,
        xTickFormatter,
        yTickFormatter,
        barStyles: this.barStyles,
        barEdgeMarks: false,
      };
    },
    getShareComponentProps(graphSlotProps) {
      return {
        barGraphProps: this.getBarGraphProps(graphSlotProps),
        title: 'התזרימים שלי ברייזאפ',
        formattedDatesRange:
            `${dateUtils.getMonthAndShortYear(this.filters.dates.start)} - ${dateUtils.getMonthAndShortYear(this.filters.dates.end)}`,
        formattedDataSections: this.filters.dataSections.excluded ? 'כולל עסקאות שהוצאת מהתזרים' : '',
        activationDateVisible: graphSlotProps.activationDateVisible,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/spacings';

.overflow-x {
  overflow-x: auto;
}

.graph-labels {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .share-icon {
    margin-left: $ri-spacing-xs;
    margin-top: $ri-spacing-sm;
    cursor: pointer;
  }
}
</style>
