<template>
  <div class="my-progress-budgets-header">
    <div class="ri-large-body margin-bottom-sm">
     בין החודשים {{ startDate | getMonthAndShortYear }} - {{ endDate | getMonthAndShortYear }}, {{ dataSectionsStrings.headerText }}:
    </div>
    <div class="margin-bottom-l">
        <animated-number class="ri-medium-display" :value="poolSum" :formatter="formatAmount" />
        <span class="ri-medium-headline">₪</span>
    </div>
    <div class="flex-row full-width space-between">
      <div class="cf-data-section margin-left-sm">
        <span class="ri-title">
          <animated-number :value="incomes" :formatter="formatAmount" />
          <span class="ri-body">₪</span>
        </span>
        <div class="ri-label">סה״כ הכנסות</div>
      </div>
      <div class="cf-data-section">
        <span class="ri-title">
          <animated-number :value="expenses" :formatter="formatAmount" />
          <span class="ri-body">₪</span>
        </span>
        <div class="ri-label">סה״כ הוצאות</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import { getCurrentCashflowMonth, getCashflowMonthFromMonthsAgo } from '@riseupil/common-utils';
import { mapState } from 'vuex';

export default {
  name: 'MyProgressBalanceHeader',
  components: {
    AnimatedNumber: BaseUI.AnimatedNumber,
  },
  props: {
    filters: {
      type: Object, // dataSections, dates
      required: true,
    },
    rangedDataset: {
      type: Array,
      required: true,
    },
    oldestCfMonth: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    startDate() {
      if (this.oldestCfMonth > this.filters.dates.start) {
        return this.oldestCfMonth;
      }
      return this.filters.dates.start;
    },
    endDate() {
      return _.min([this.filters.dates.end, getCashflowMonthFromMonthsAgo(getCurrentCashflowMonth(this.cashflowStartDay), 1)]);
    },
    dataSectionsStrings() {
      if (this.filters.dataSections.excluded) {
        return {
          headerText: 'סך כל התזרימים שלך כולל עסקאות שהוצאת מהתזרים, מסתכמים ב',
          incomesAndExpenses: '',
        };
      }
      return {
        headerText: 'סך כל התזרימים שלך מסתכמים ב',
        incomesAndExpenses: 'תזרימיות',
      };
    },
    cashflowsTotalIncome() {
      return Math.abs(_.sumBy(this.rangedDataset, ({ cashflowIncomes = 0 }) => cashflowIncomes));
    },
    cashflowTotalExpenses() {
      return Math.abs(_.sumBy(this.rangedDataset, ({ cashflowExpenses = 0 }) => cashflowExpenses));
    },
    excludedTotalIncome() {
      return Math.abs(_.sumBy(this.rangedDataset, ({ incomeExclusionsThatAreNotSavings = 0 }) => incomeExclusionsThatAreNotSavings));
    },
    excludedTotalExpenses() {
      return Math.abs(_.sumBy(this.rangedDataset,
        ({ excludedSavings = 0, expenseExclusionsThatAreNotSavings = 0 }) => expenseExclusionsThatAreNotSavings + excludedSavings));
    },
    poolSum() {
      return 0
        + (this.filters.dataSections.cashflows ? this.cashflowsTotalIncome - this.cashflowTotalExpenses : 0)
        + (this.filters.dataSections.excluded ? this.excludedTotalIncome - this.excludedTotalExpenses : 0);
    },
    incomes() {
      return 0
          + (this.filters.dataSections.cashflows ? this.cashflowsTotalIncome : 0)
          + (this.filters.dataSections.excluded ? this.excludedTotalIncome : 0);
    },
    expenses() {
      return 0
          + (this.filters.dataSections.cashflows ? this.cashflowTotalExpenses : 0)
          + (this.filters.dataSections.excluded ? this.excludedTotalExpenses : 0);
    },
  },
  methods: {
    formatAmount(value) {
      return `${moneyUtils.formatAmountWithFraction(value, 0)}${value < 0 ? '-' : ''}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/border-radius";
@import "~@riseupil/base-ui/src/scss/typography";

.my-progress-budgets-header {
  width: 100%;
  background: $riseup_beige;
  padding-top: $ri-spacing-sm;
  text-align: right;
  color: $riseup_black;

  .cf-data-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #F5F1E1;
    border-radius: $card-border-radius;
    padding: $ri-spacing-sm;
  }
}
</style>
